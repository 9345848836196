import React from "react";

type Props = { sx?: any };

export const FooterLogo = (props: Props) => (
  <svg
    width="230"
    height="96"
    viewBox="0 0 230 96"
    className="border bg-milk w-full h-full"
    {...props}
  >
    <path
      d="M26.0854 61.9635V35.6343H38.8698C46.2385 35.6343 49.9228 38.5224 49.9228 44.3008C49.9228 47.2514 48.9775 49.4433 47.0848 50.8831C45.1941 52.3207 42.3087 53.0406 38.4262 53.0406H31.9059V61.9635H26.0854ZM38.3896 48.4304C40.3556 48.4304 41.7919 48.0941 42.7006 47.4174C43.6093 46.7406 44.0636 45.691 44.0636 44.2641C44.0636 42.8632 43.62 41.8416 42.7372 41.2036C41.8522 40.5635 40.4288 40.2445 38.4628 40.2445H31.9059V48.4304H38.3896Z"
      fill="#0E1116"
    />
    <path
      d="M61.8587 61.9635V51.4543L50.7324 35.6343H57.3281L64.8798 46.6975L72.5048 35.6343H78.8786L67.7524 51.4543V61.9635H61.8587Z"
      fill="#0E1116"
    />
    <path
      d="M106.4 52.8552C105.983 55.9782 104.577 58.3879 102.182 60.0819C99.7875 61.7782 96.6243 62.6252 92.6945 62.6252C89.7466 62.6252 87.1669 62.0842 84.9575 61.0023C82.7482 59.9203 81.0406 58.3361 79.8369 56.2455C78.6332 54.157 78.0303 51.6719 78.0303 48.7967C78.0303 46.0681 78.6138 43.6649 79.7809 41.5872C80.948 39.5095 82.6363 37.8865 84.8456 36.7205C87.057 35.5523 89.6583 34.9683 92.6557 34.9683C99.9491 34.9683 104.383 37.9792 105.955 44.0012L100.095 44.7016C99.4818 42.9558 98.5903 41.7036 97.4253 40.9406C96.2604 40.1776 94.6691 39.7983 92.6557 39.7983C90.0265 39.7983 87.9528 40.5979 86.4304 42.195C84.908 43.7921 84.1457 45.9927 84.1457 48.7946C84.1457 51.6223 84.9059 53.8272 86.4304 55.4135C87.9528 56.9998 90.0652 57.7908 92.7655 57.7908C94.9512 57.7908 96.6717 57.3856 97.9228 56.5752C99.176 55.7648 100.009 54.5234 100.427 52.8509H106.4V52.8552Z"
      fill="#0E1116"
    />
    <path
      d="M140.404 48.7987C140.404 51.5769 139.795 54.0038 138.578 56.0815C137.363 58.1592 135.606 59.7692 133.309 60.9115C131.011 62.0538 128.281 62.625 125.111 62.625C122.064 62.625 119.407 62.0776 117.135 60.9848C114.863 59.8899 113.095 58.3058 111.829 56.228C110.563 54.1503 109.932 51.6739 109.932 48.7987C109.932 46.0205 110.539 43.5936 111.756 41.5159C112.97 39.4382 114.728 37.8282 117.025 36.6858C119.321 35.5435 122.053 34.9702 125.223 34.9702C128.268 34.9702 130.927 35.5177 133.199 36.6104C135.471 37.7053 137.239 39.2916 138.505 41.3672C139.771 43.447 140.404 45.9235 140.404 48.7987ZM134.288 48.7987C134.288 45.9472 133.49 43.7337 131.894 42.1603C130.296 40.587 128.05 39.8003 125.152 39.8003C122.277 39.8003 120.042 40.5934 118.446 42.1776C116.851 43.7639 116.052 45.9709 116.052 48.7965C116.052 51.648 116.851 53.8615 118.446 55.4349C120.042 57.0083 122.29 57.7949 125.188 57.7949C128.063 57.7949 130.296 57.0018 131.894 55.4176C133.49 53.8335 134.288 51.6265 134.288 48.7987Z"
      fill="#0E1116"
    />
    <path
      d="M166.119 61.9635L150.903 43.5637V61.9635H145.266V35.6343H150.645L165.862 54.0342V35.6343H171.498V61.9635H166.119Z"
      fill="#0E1116"
    />
    <path
      d="M179.385 43.6388C177.458 41.337 176.496 38.6687 176.496 35.634C176.496 32.5993 177.458 29.9311 179.385 27.6313H182.945C182.062 28.9245 181.416 30.2134 181.007 31.5023C180.598 32.789 180.391 34.1684 180.391 35.6362C180.391 37.1039 180.596 38.4812 181.007 39.7701C181.418 41.0568 182.064 42.3478 182.945 43.6432H179.385V43.6388Z"
      fill="#0E1116"
    />
    <path
      d="M184.773 41.3264V28.0044H188.688V41.3264H184.773Z"
      fill="#0E1116"
    />
    <path
      d="M195.4 41.3264V31.2137H190.441V28.0044H204.272V31.2137H199.313V41.3264H195.4Z"
      fill="#0E1116"
    />
    <path
      d="M204.795 43.6388C205.678 42.3456 206.324 41.0545 206.733 39.7657C207.144 38.4789 207.349 37.1017 207.349 35.6318C207.349 34.164 207.144 32.7868 206.733 31.4979C206.322 30.2112 205.676 28.9201 204.795 27.627H208.354C210.282 29.9267 211.244 32.5949 211.244 35.6296C211.244 38.6643 210.282 41.3326 208.354 43.6344H204.795V43.6388Z"
      fill="#0E1116"
    />
  </svg>
);
