import React from "react";

export const Logo = (props: any) => (
  <svg {...props} viewBox="0 0 1500 450" fill="none">
    <g clipPath="url(#clip0_3051_157)">
      <path d="M1056.79 8.5H8.5V441.5H1056.79V8.5Z" fill="white" />
      <path
        d="M1048.29 17V433H17V17H1048.29ZM1065.29 0H0V450H1065.29V0Z"
        fill="black"
      />
      <path
        d="M121.14 287.49V165.33H180.51C214.73 165.33 231.84 178.73 231.84 205.54C231.84 219.23 227.45 229.4 218.66 236.08C209.88 242.75 196.48 246.09 178.45 246.09H148.17V287.49H121.14ZM178.28 224.7C187.41 224.7 194.08 223.14 198.3 220C202.52 216.86 204.63 211.99 204.63 205.37C204.63 198.87 202.57 194.13 198.47 191.17C194.36 188.2 187.75 186.72 178.62 186.72H148.17V224.7H178.28Z"
        fill="black"
      />
      <path
        d="M287.27 287.49V238.73L235.6 165.33H266.23L301.3 216.66L336.71 165.33H366.31L314.64 238.73V287.49H287.27Z"
        fill="black"
      />
      <path
        d="M494.12 245.23C492.18 259.72 485.65 270.9 474.53 278.76C463.41 286.63 448.72 290.56 430.47 290.56C416.78 290.56 404.8 288.05 394.54 283.03C384.28 278.01 376.35 270.66 370.76 260.96C365.17 251.27 362.37 239.74 362.37 226.4C362.37 213.74 365.08 202.59 370.5 192.95C375.92 183.31 383.76 175.78 394.02 170.37C404.29 164.95 416.37 162.24 430.29 162.24C464.16 162.24 484.75 176.21 492.05 204.15L464.84 207.4C461.99 199.3 457.85 193.49 452.44 189.95C447.03 186.41 439.64 184.65 430.29 184.65C418.08 184.65 408.45 188.36 401.38 195.77C394.31 203.18 390.77 213.39 390.77 226.39C390.77 239.51 394.3 249.74 401.38 257.1C408.45 264.46 418.26 268.13 430.8 268.13C440.95 268.13 448.94 266.25 454.75 262.49C460.57 258.73 464.44 252.97 466.38 245.21H494.12V245.23Z"
        fill="black"
      />
      <path
        d="M652.03 226.41C652.03 239.3 649.2 250.56 643.55 260.2C637.91 269.84 629.75 277.31 619.08 282.61C608.41 287.91 595.73 290.56 581.01 290.56C566.86 290.56 554.52 288.02 543.97 282.95C533.42 277.87 525.21 270.52 519.33 260.88C513.45 251.24 510.52 239.75 510.52 226.41C510.52 213.52 513.34 202.26 518.99 192.62C524.63 182.98 532.79 175.51 543.46 170.21C554.12 164.91 566.81 162.25 581.53 162.25C595.67 162.25 608.02 164.79 618.57 169.86C629.12 174.94 637.33 182.3 643.21 191.93C649.09 201.58 652.03 213.07 652.03 226.41ZM623.63 226.41C623.63 213.18 619.92 202.91 612.51 195.61C605.09 188.31 594.66 184.66 581.2 184.66C567.85 184.66 557.47 188.34 550.06 195.69C542.65 203.05 538.94 213.29 538.94 226.4C538.94 239.63 542.65 249.9 550.06 257.2C557.47 264.5 567.91 268.15 581.37 268.15C594.72 268.15 605.09 264.47 612.51 257.12C619.92 249.77 623.63 239.53 623.63 226.41Z"
        fill="black"
      />
      <path
        d="M771.45 287.49L700.79 202.12V287.49H674.61V165.33H699.59L770.26 250.7V165.33H796.43V287.49H771.45Z"
        fill="black"
      />
      <path
        d="M833.06 202.47C824.11 191.79 819.64 179.41 819.64 165.33C819.64 151.25 824.11 138.87 833.06 128.2H849.59C845.49 134.2 842.49 140.18 840.59 146.16C838.69 152.13 837.73 158.53 837.73 165.34C837.73 172.15 838.68 178.54 840.59 184.52C842.5 190.49 845.5 196.48 849.59 202.49H833.06V202.47Z"
        fill="black"
      />
      <path d="M858.08 191.74V129.93H876.26V191.74H858.08Z" fill="black" />
      <path
        d="M907.43 191.74V144.82H884.4V129.93H948.63V144.82H925.6V191.74H907.43Z"
        fill="black"
      />
      <path
        d="M951.06 202.47C955.16 196.47 958.16 190.48 960.06 184.5C961.97 178.53 962.92 172.14 962.92 165.32C962.92 158.51 961.97 152.12 960.06 146.14C958.15 140.17 955.15 134.18 951.06 128.18H967.59C976.54 138.85 981.01 151.23 981.01 165.31C981.01 179.39 976.54 191.77 967.59 202.45H951.06V202.47Z"
        fill="black"
      />
      <path d="M1491.5 8.5H1058.5V441.5H1491.5V8.5Z" fill="#F17A5D" />
      <path
        d="M1483 17V433H1067V17H1483ZM1500 0H1050V450H1500V0Z"
        fill="black"
      />
      <path
        d="M1167.75 291V272.016C1173.8 267.76 1179.12 264.008 1183.71 260.76C1188.42 257.512 1192.62 254.544 1196.31 251.856C1200.12 249.168 1203.7 246.592 1207.06 244.128C1215.35 238.08 1221.57 233.152 1225.71 229.344C1229.86 225.424 1232.71 222.008 1234.28 219.096C1235.96 216.072 1236.8 212.824 1236.8 209.352C1236.8 203.752 1234.95 199.496 1231.26 196.584C1227.67 193.672 1222.35 192.216 1215.3 192.216C1208.46 192.216 1203.14 193.616 1199.34 196.416C1195.53 199.216 1192.73 203.808 1190.94 210.192L1166.58 207.504C1168.82 195.744 1174.14 186.84 1182.54 180.792C1191.05 174.744 1202.36 171.72 1216.47 171.72C1231.14 171.72 1242.57 174.856 1250.74 181.128C1258.92 187.4 1263.01 196.08 1263.01 207.168C1263.01 212.88 1261.94 218.032 1259.82 222.624C1257.8 227.104 1254.5 231.64 1249.9 236.232C1245.31 240.712 1239.04 245.752 1231.09 251.352C1222.91 257.176 1213.73 263.448 1203.54 270.168H1265.36L1262.17 291H1167.75ZM1318.98 241.272V220.776H1334.27C1341.22 220.776 1346.54 219.544 1350.23 217.08C1353.93 214.504 1355.78 210.864 1355.78 206.16C1355.78 201.456 1353.82 197.928 1349.9 195.576C1346.09 193.112 1340.43 191.88 1332.93 191.88C1325.98 191.88 1320.55 193.224 1316.63 195.912C1312.82 198.6 1310.08 203.024 1308.4 209.184L1284.54 205.992C1286.45 195.016 1291.66 186.56 1300.17 180.624C1308.68 174.688 1319.94 171.72 1333.94 171.72C1348.27 171.72 1359.53 174.52 1367.7 180.12C1375.99 185.72 1380.14 193.336 1380.14 202.968C1380.14 215.512 1373.58 224.248 1360.48 229.176C1375.94 234.216 1383.66 243.736 1383.66 257.736C1383.66 269.048 1379.24 277.952 1370.39 284.448C1361.66 290.832 1349.39 294.024 1333.6 294.024C1318.03 294.024 1305.82 290.608 1296.98 283.776C1288.24 276.832 1283.48 266.976 1282.7 254.208H1307.22C1308.23 261.152 1310.75 266.192 1314.78 269.328C1318.82 272.352 1324.98 273.864 1333.26 273.864C1341.44 273.864 1347.66 272.464 1351.91 269.664C1356.28 266.752 1358.46 262.496 1358.46 256.896C1358.46 246.48 1350.23 241.272 1333.77 241.272H1318.98Z"
        fill="#1D1D1B"
      />
    </g>
    <defs>
      <clipPath id="clip0_3051_157">
        <rect width="1500" height="450" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const MobileLogo = (props) => {
  return (
    <svg fill="none" viewBox="0 0 120 60" {...props}>
      <g clipPath="url(#clip0_591_6312)">
        <rect width="120" height="60" fill="#FCE8DE" />
        <path d="M120 60L120 0L60 0L60 60L120 60Z" fill="#F17A5D" />
        <path
          d="M76.1562 37.8482V35.3259C76.9598 34.7604 77.6667 34.2619 78.2768 33.8304C78.9018 33.3988 79.4598 33.0045 79.9509 32.6473C80.4568 32.2902 80.933 31.9479 81.3795 31.6205C82.4807 30.817 83.3065 30.1622 83.8571 29.6562C84.4077 29.1354 84.7872 28.6815 84.9955 28.2946C85.2188 27.8929 85.3304 27.4613 85.3304 27C85.3304 26.256 85.0848 25.6905 84.5938 25.3036C84.1176 24.9167 83.4107 24.7232 82.4732 24.7232C81.5655 24.7232 80.8586 24.9092 80.3527 25.2812C79.8467 25.6533 79.4747 26.2634 79.2366 27.1116L76 26.7545C76.2976 25.192 77.0045 24.0089 78.1205 23.2054C79.2515 22.4018 80.7545 22 82.6295 22C84.5789 22 86.0967 22.4167 87.183 23.25C88.2693 24.0833 88.8125 25.2366 88.8125 26.7098C88.8125 27.4688 88.6711 28.1533 88.3884 28.7634C88.1205 29.3586 87.6815 29.9613 87.0714 30.5714C86.4613 31.1667 85.628 31.8363 84.5714 32.5804C83.4851 33.3542 82.2649 34.1875 80.9107 35.0804H89.125L88.7009 37.8482H76.1562Z"
          fill="#0E1116"
        />
        <path
          d="M96.2499 31.2411V28.5179H98.2811C99.2038 28.5179 99.9106 28.3542 100.402 28.0268C100.893 27.6845 101.138 27.2009 101.138 26.5759C101.138 25.9509 100.878 25.4821 100.357 25.1696C99.8511 24.8423 99.0996 24.6786 98.1026 24.6786C97.18 24.6786 96.4582 24.8571 95.9374 25.2143C95.4314 25.5714 95.0669 26.1592 94.8436 26.9777L91.674 26.5536C91.927 25.0952 92.6189 23.9717 93.7499 23.183C94.8808 22.3943 96.3764 22 98.2365 22C100.141 22 101.637 22.372 102.723 23.1161C103.824 23.8601 104.375 24.872 104.375 26.1518C104.375 27.8185 103.504 28.9792 101.763 29.6339C103.817 30.3036 104.844 31.5685 104.844 33.4286C104.844 34.9315 104.256 36.1146 103.08 36.9777C101.92 37.8259 100.29 38.25 98.1919 38.25C96.1234 38.25 94.5014 37.7961 93.3258 36.8884C92.1651 35.9658 91.5326 34.6562 91.4285 32.9598H94.6874C94.8213 33.8824 95.1561 34.5521 95.6919 34.9688C96.2276 35.3705 97.046 35.5714 98.1472 35.5714C99.2335 35.5714 100.059 35.3854 100.625 35.0134C101.205 34.6265 101.495 34.061 101.495 33.317C101.495 31.933 100.402 31.2411 98.2142 31.2411H96.2499Z"
          fill="#0E1116"
        />
        <path
          d="M38.837 20.7725C39.6582 23.8 39.0915 27.2611 37.1012 29.3283C34.7411 31.7802 34.7411 36.7728 37.1012 39.2218C40.1051 42.3426 40.1051 48.4642 37.1012 51.582C34.7411 54.031 34.7426 59.0236 37.1012 61.477C38.2199 62.6371 38.9198 64.2219 39.2025 65.9133H42.7213V30.188C42.7213 26.6041 41.2874 23.2377 38.837 20.7725Z"
          fill="#F17A5D"
        />
        <path
          d="M35.8214 50.3496C38.1816 47.9006 38.1801 42.908 35.8214 40.4546C32.819 37.3382 32.8175 31.2151 35.8214 28.0943C38.0055 25.8259 37.9019 20.9872 35.6512 18.405C33.7512 17.4003 31.6234 16.8853 29.4837 16.9283C22.7775 17.0629 17.2625 22.4048 16.8053 29.0147H29.2972L30.1999 29.9144V65.9134H37.4032C37.1487 64.6823 36.6264 63.5444 35.8214 62.7098C32.8175 59.589 32.8175 53.4674 35.8214 50.3496ZM26.0596 23.8223C25.2102 23.8223 24.5221 23.1313 24.5221 22.2805C24.5221 21.4311 25.2102 20.743 26.0596 20.743C26.9089 20.743 27.6 21.4311 27.6 22.2805C27.6 23.1298 26.9089 23.8223 26.0596 23.8223Z"
          fill="#FCE8DE"
        />
        <path
          d="M26.0596 20.7432C25.2102 20.7432 24.5222 21.4312 24.5222 22.2806C24.5222 23.13 25.2102 23.8225 26.0596 23.8225C26.909 23.8225 27.6 23.1315 27.6 22.2806C27.6 21.4312 26.909 20.7432 26.0596 20.7432Z"
          fill="#0E1116"
        />
        <path
          d="M38.8371 20.7728C41.2876 23.2381 42.7229 26.6045 42.7229 30.1884V65.9136H44.5001V30.1884C44.5001 24.7104 41.5465 19.6749 36.7847 17.001C36.8217 17.0394 36.8587 17.0779 36.8898 17.1164C37.8176 18.1359 38.4672 19.4115 38.8371 20.7728Z"
          fill="#0E1116"
        />
        <path
          d="M30.1998 65.9135V29.9145L29.2972 29.0148H16.8053C17.2625 22.4048 22.776 17.063 29.4836 16.9283C31.6219 16.8854 33.7512 17.4003 35.6512 18.4051C35.6201 18.3696 35.5935 18.3311 35.5624 18.2956L35.521 18.2468C35.5121 18.2349 35.5002 18.2246 35.4899 18.2127L36.7314 16.9653C36.7018 16.949 36.6737 16.9298 36.6441 16.9135C34.4378 15.712 31.9548 15.0964 29.4511 15.1497C21.4901 15.3095 15.0074 21.9209 15 29.8878L15.8997 30.7905H28.4256V65.912H30.1998V65.9135Z"
          fill="#0E1116"
        />
        <path
          d="M35.4884 18.2153C35.5002 18.2257 35.5106 18.2375 35.5195 18.2493L35.5609 18.2982C35.592 18.3322 35.6186 18.3722 35.6497 18.4077C37.9018 20.9913 38.0039 25.83 35.8199 28.097C32.816 31.2177 32.8175 37.3394 35.8199 40.4572C38.18 42.9091 38.18 47.9032 35.8199 50.3522C32.816 53.4685 32.816 59.5917 35.8199 62.7124C36.6248 63.547 37.1472 64.6849 37.4017 65.9161H39.2025C38.9199 64.2232 38.22 62.6384 37.1013 61.4798C34.7411 59.0279 34.7411 54.0338 37.1013 51.5848C40.1052 48.467 40.1052 42.3454 37.1013 39.2246C34.7411 36.7756 34.7426 31.783 37.1013 29.3311C39.0915 27.2639 39.6583 23.8028 38.837 20.7753C38.4671 19.4124 37.8175 18.1384 36.8897 17.1173C36.8586 17.0789 36.8202 17.0404 36.7846 17.0019C36.7698 16.9871 36.758 16.9709 36.7432 16.9561L36.7299 16.9694L35.4884 18.2153Z"
          fill="#0E1116"
        />
        <path
          d="M42.9567 15.6825C42.9567 15.6544 42.9581 15.6263 42.9611 15.5967C42.9596 15.6263 42.9567 15.6544 42.9567 15.6825Z"
          fill="#0E1116"
        />
        <path
          d="M43.2163 16.3104C43.1954 16.2895 43.1789 16.2685 43.1609 16.2461C43.1789 16.2685 43.1954 16.291 43.2163 16.3104Z"
          fill="#0E1116"
        />
        <path
          d="M42.9728 15.8539C42.9669 15.8258 42.964 15.7962 42.961 15.7666C42.964 15.7962 42.9684 15.8243 42.9728 15.8539Z"
          fill="#0E1116"
        />
        <path
          d="M43.0855 16.1485C43.0915 16.1574 43.0975 16.1634 43.1035 16.1724C43.0885 16.1499 43.0751 16.126 43.0631 16.1006C43.0706 16.1155 43.0751 16.132 43.0855 16.1485Z"
          fill="#0E1116"
        />
        <path
          d="M43.217 15.0568C43.238 15.0359 43.2604 15.018 43.2829 15C43.2589 15.018 43.2365 15.0374 43.217 15.0568Z"
          fill="#0E1116"
        />
        <path
          d="M43.3532 16.422C43.3265 16.4042 43.3014 16.3835 43.2777 16.3643C43.3029 16.385 43.328 16.4057 43.3532 16.422Z"
          fill="#0E1116"
        />
        <path
          d="M42.9745 15.5121C42.9805 15.484 42.9879 15.4558 42.9967 15.4277C42.9879 15.4558 42.979 15.484 42.9745 15.5121Z"
          fill="#0E1116"
        />
        <path
          d="M43.0219 16.0169C43.0115 15.9903 43.0027 15.9622 42.9938 15.9355C43.0027 15.9637 43.0115 15.9903 43.0219 16.0169Z"
          fill="#0E1116"
        />
        <path
          d="M43.0233 15.349C43.0352 15.3194 43.0499 15.2913 43.0662 15.2617C43.0499 15.2898 43.0352 15.3194 43.0233 15.349Z"
          fill="#0E1116"
        />
        <path
          d="M43.1032 15.1956C43.121 15.1675 43.1417 15.1423 43.1624 15.1172C43.1417 15.1423 43.121 15.1675 43.1032 15.1956Z"
          fill="#0E1116"
        />
        <path
          d="M23.4835 30.0039V34.6103L21.3705 36.7249V30.0039H25.5981V34.6103L23.4835 36.7249V34.6192"
          fill="#E94135"
        />
        <path
          d="M20.8362 38.0142V29.4717H26.1307V34.8328L22.9492 38.0142V35.8996L20.8362 38.0142ZM24.0176 34.8328V35.438L25.0653 34.3903V30.5371H24.0176V34.8328ZM21.9031 30.5371V35.438L22.9507 34.3903V30.5371H21.9031Z"
          fill="#0E1116"
        />
        <line
          x1="60.5"
          y1="-1"
          x2="60.5"
          y2="60"
          stroke="#0E1116"
          strokeWidth="3"
        />
      </g>
      <rect
        x="1.5"
        y="1.5"
        width="117"
        height="57"
        stroke="#0E1116"
        strokeWidth="3"
      />
      <defs>
        <clipPath id="clip0_591_6312">
          <rect width="120" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
